console.log("Script running");

document.querySelector("#napkinCoronavirus").addEventListener("click", () => {
  gtag("event", "click", { event_category: "clicked_Coronavirus" });
  window.location = "https://www.napkinprojects.com/coronavirus/index.html";
});

document.querySelector("#napkinWhereDidWeGo").addEventListener("click", () => {
  gtag("event", "click", { event_category: "clicked_WhereDidWeGo" });
  window.location = "https://www.wheredidwego.com";
});

document.querySelector("#napkinValuesExercise").addEventListener("click", () => {
  gtag("event", "click", { event_category: "clicked_ValuesExercise" });
  window.location = "https://www.napkinprojects.com/valuesExercise/index.html";
});

document.querySelector("#napkinTempestNavMagic").addEventListener("click", () => {
  gtag("event", "click", { event_category: "clicked_TempestNavMagic" });
  window.location = "https://www.napkinprojects.com/tempestNavMagic/index.html";
});

document.querySelector("#napkinStillplanning").addEventListener("click", () => {
  console.log("clicked still planning");
  gtag("event", "click", { event_category: "clicked_Stillplanning" });
});

document.querySelector("#signatureLink").addEventListener("click", () => {
  console.log("clicked signatureLink");
  gtag("event", "click", { event_category: "clicked_Signature" });
});
